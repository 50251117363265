
import { UserClient } from "@/api/server";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  required,
  required_if,
  email,
  max,
  min,
  confirmed,
} from "vee-validate/dist/rules";
import Vue from "vue";
import Component from "vue-class-component";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("required_if", {
  ...required_if,
});
extend("email", {
  ...email,
  message: "A valid email is required",
});
extend("max", {
  ...max,
  message: "This field must be {length} characters or less",
});
extend("min", {
  ...min,
  message: "This field must be {length} characters or more",
});
extend("confirmed", {
  ...confirmed,
  message: "The password and confirmation must match",
});
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Register extends Vue {
  defaultItem = {
    name: null,
    email: null,
    password: null,
    password_confirmation: null,
  };
  editedItem = {
    name: null,
    email: null,
    password: null,
    password_confirmation: null,
  };
  loading = false;
  menu = false;
  show = false;
  stp = 1;
  /**
   * Watch
   */
  // watch: {
  //   $route: {
  //     handler: function (route) {
  //       const query = route.query;
  //       if (query) {
  //         this.redirect = query.redirect;
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  /**
   * Register
   */
  private register(): void {
    UserClient.register(this.editedItem)
      .then(() => {
        return this.$router.push({ name: "auth.login" });
      })
      .catch((error) => {
        console.log(error);
      });
    // this.loading = true;
    // axios
    //   .post("/register", this.editedItem)
    //   .then(() => {
    //     this.loading = false;
    //     this.stp = 2;
    //     setTimeout(() => {
    //       window.location = "/";
    //     }, 5000);
    //   })
    //   .catch((error) => {
    //     setTimeout(() => {
    //       this.loading = false;
    //       this.$refs.form.setErrors(error.response.data.errors);
    //       /**
    //        * Set snackbar message
    //        */
    //       if (error.response.data.message) {
    //         this.$store.commit({
    //           color: "success",
    //           message: error.response.data.message,
    //           toggle: true,
    //           type: "flashAlert",
    //         });
    //         this.$store.commit({
    //           color: "success",
    //           message: error.response.data.message,
    //           toggle: true,
    //           type: "snackbar",
    //         });
    //       }
    //     }, 3000);
    //   });
  }
}
